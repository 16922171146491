import { createSlice } from "@reduxjs/toolkit";

function getLocalData()
{
    const uinfo = localStorage.getItem('clientinfo');
    if(uinfo==undefined){
        return {
            brokers : []
        }
    }else
        return JSON.parse(uinfo)
}

const slice = createSlice({
    name : 'clientdataslice',
    initialState:{
        value : getLocalData()
    },
    reducers:
    {
       addBrokers : (state,action)=>{
            state.value = {...state.value , brokers : action.payload}
       },
       addNewBroker : (state,action)=>{
            state.value = {...state.value , brokers : [...state.value.brokers,action.payload]}
       }
    }
})

export default slice.reducer;
export const {addBrokers,addNewBroker} = slice.actions;