
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
export default function Profile({ show, handleClose }){
    return<div className={`modal ${show ? "display-block" : "display-none"} profiler`}>
    <div className="modal-content ">
      <span className="close" onClick={handleClose}>
        &times;
      </span>
      <div className="container container-main">
      <div className="left-panel">
        <div className="image-container">
          <div className="image-circle">
            <img
              src="./images/malecarousel.png"
              alt="placeholder"
              className="image"
            />
          </div>
        </div>
      </div>
      <div className="right-panel">
      <Container className="my-5">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="text-center">My Profile</h2>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                // value={user.name}
                // onChange={handleInputChange}
                // readOnly={!isEditing}
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                // value={user.email}
                // onChange={handleInputChange}
                // readOnly={!isEditing}
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                // value={user.phone}
                // onChange={handleInputChange}
                // readOnly={!isEditing}
              />
            </Form.Group>

            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                // value={user.address}
                // onChange={handleInputChange}
                // readOnly={!isEditing}
              />
            </Form.Group>

            {/* {isEditing ? ( */}
              <Button variant="success" 
              // onClick={handleSaveClick} 
              className="mt-3">
                Save
              </Button>
            {/* ) : (
              <Button variant="primary" 
              onClick={handleEditClick} 
              className="mt-3">
                Edit
              </Button>
            )} */}  
          </Form>
        </Col>
      </Row>
    </Container>
      </div>
    </div>
      {/* <button onClick={handleClose} className="submit-btn">Save Changes</button> */}
    </div>
  </div>
}