
import { useState } from "react";
import "./Instrumentpop.css"

const Instrument = ({ show, handleClose, type,setInstrument }) => 
{
  const instrumentTypes = [
    {name:"NIFTY 50" , lot:75},
    {name:"NIFTY Bank" , lot:30},
    {name:"NIFTY FIN" , lot:65}
  ]

  const options = [
    {name : "INDICES" , disabled : type},
    {name : "OPTIONS" , disabled : false},
    {name : "FUTURE" , disabled : type}
  ]

  const [selectOption,setSelectOption] = useState("OPTIONS")
  const [selectInstrument,setSelectInstrument] = useState(undefined)
  return (
      <div className={`modal ${show ? "display-block" : "display-none"}`}>
        <div className="modal-content">
          <span className="close" onClick={()=>{
            setSelectOption('OPTIONS');
            setSelectInstrument(undefined);
            handleClose()
            }}>
            &times;
          </span>
          <h2>Select an Option</h2>
          <form>
            <div className="row">
            {options.map((ob,index)=><label key={`op${index}`} className="col-xl-4">
              <input type="radio" name="option" disabled={ob.disabled} value={ob.name} checked={ob.name==selectOption} onChange={()=>setSelectOption(ob.name)}/>
              {ob.name}
            </label>)}
            </div>
          </form>
          <table className="table">
            <tbody>
            {instrumentTypes.map((ob,i)=><tr key={`instrumentType${i}`}  className={selectInstrument?.name==ob.name?"table-success":""} onClick={()=>setSelectInstrument(ob)}><td>{ob.name}</td></tr>)}           
            </tbody>
          </table>
          <button onClick={()=>{           
            setInstrument({optype:selectOption,name:selectInstrument.name,lot:selectInstrument.lot})
            setSelectOption('OPTIONS');
            setSelectInstrument(undefined);
            handleClose()
            }} className="submit-btn">Submit</button>
        </div>
      </div>
    );
  };


  export default Instrument;
  