import toast from "react-hot-toast";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";

export default function AddBrokerForm({ setIsAdd, mbrokers, addNewBroker }) 
{  
  const totpBoxRef = useRef(undefined);
 
  const token = useSelector((store) => store.user.value.token);
  const [selectbroker, setSelectBroker] = useState(undefined);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var formdata = { ...selectbroker.formdata };
    formdata[name] = value;
    setSelectBroker({ ...selectbroker, formdata });
  };

  const assignTotp = (totp)=>{}

  const brokerLoginAndSave = (credentials, event) => {
    toast.promise(
      apiCall.postCallWithToken(URLS.BROKER_LOGIN, token, credentials),
      {
        msg: "Broker Saving ..",
        success: (result) => {
          console.log(result);
          if (result.status) {
            apiCall
              .postCallWithToken(URLS.SAVE_BROKER, token, selectbroker)
              .then((result) => {
                console.log(result)
                if (result.status) {
                  toast.success(result.msg);
                  addNewBroker(result.data);
                  event.target.reset();
                } else {
                  toast.error("Broker Save Failed !");
                }
              })
              .catch((result) => {
                toast.error("Broker Save Failed !");
              });
          } else {
            toast.error("Broker Connection Failed !");
          }
        },
        error: (result) => {
          console.log(result);
          toast.error("Broker Connection Failed !");
        },
      }
    );
  };

  const save = (event) => {
    event.preventDefault();

    console.log(selectbroker);
    var credentials = {...selectbroker};
    // if (selectbroker.brokerui.istotp) 
    // {
    //   credentials = {...credentials,formdata: {...credentials.formdata,totp:totpBoxRef.current.value}}
    // } 
    brokerLoginAndSave(credentials, event);    
  };

  return (
    <>
        <form onSubmit={save}>
        <div className="row">
          <h4>Choose Broker</h4>
          {mbrokers.map((ob,i) => (
              <div key={`mbroker${i}`} className="col-xl-1 col-lg-1 text-center">
            <Tooltip title={`Click to add ${ob.name}`}>
             
              <img
                onClick={() =>
                  setSelectBroker({
                    name: ob.name,
                    brokerui: JSON.parse(ob.uidetails),
                    formdata: {},
                  })
                }
                src={`/images/${ob.image}`}
                className="brokerlogo"
                alt="broker-logo"
              />
              
            </Tooltip>
            </div>
          ))}
        </div>
        {selectbroker !== undefined ? (
          <>
            <div className="row mt-3">
              {Object.keys(selectbroker?.brokerui)
                .filter((k) => k != "istotp")
                .map((key,i) => (
                  <div key={`sbroker${i}`} className="col-xl-3 col-lg-3 p-2">
                    <input
                      className="form-control"
                      type={selectbroker.brokerui[key].type}
                      placeholder={selectbroker.brokerui[key].title}
                      onChange={handleChange}
                      name={key}
                      required
                    />
                  </div>
                ))}
            </div>
            <div className="row mt-3">
              {/* <div className="col-xl-2 col-lg-2">
                {selectbroker?.brokerui.istotp?<input type="number" ref={totpBoxRef} className="form-control" placeholder="TOTP" required/>:""}
              </div> */}
              <div className="col-xl-2 col-lg-2">
                <button
                  className="btn btn-success mt-2"
                  disabled={selectbroker === undefined}
                >
                  Add Broker
                </button>
              </div>
              <div className="col-xl-2 col-lg-2">
                <button
                  onClick={() => {
                    setIsAdd(false);
                    setSelectBroker(undefined);
                  }}
                  className="btn btn-primary mt-2"
                >
                  Close
                </button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </form>
    </>
  );
}
