import { useEffect, useState } from "react";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CurrencyRupee, EditSharp, Padding } from "@mui/icons-material";
import { FaChevronDown, FaTrash } from "react-icons/fa";
import { useRef } from "react";
import toast from "react-hot-toast";

export default function ClientDeployStrategyList() {
  const navigate = useNavigate();
  const [deployList, setDeployList] = useState([]);
  const token = useSelector((store) => store.user.value.token);

  useEffect(() => {
    loadDeployStrategy();
  }, []);

  const removeFromList = (id) => {
    setDeployList(deployList.filter((ob) => ob._id != id));
  };

  const updateDeployItem = (id, newItem) => {
    setDeployList(deployList.map((ob) => (ob._id == id ? newItem : ob)));
  };

  const loadDeployStrategy = async () => {
    const depStrList = await apiCall.getCallWithToken(
      URLS.LIST_DEPLOY_STRATEGY,
      token
    );
    setDeployList(depStrList.data);
  };

  return (
    <>
      <div className="container-fluid pt-3">
        <h3 id="HeadText">Deployed Strategies</h3>

        {deployList.map((ob, index) => (
          <div
            key={`dp${index}`}
            className="row mt-1 border shadow rounded-2 p-3"
          >
            <DeployListItem
              obj={ob}
              token={token}
              key={`dpi${index}`}
              removeFromList={removeFromList}
              updateDeployItem={updateDeployItem}
            />
          </div>
        ))}
      </div>
    </>
  );
}

const DeployListItem = ({ obj, token, removeFromList, updateDeployItem }) => 
{
  const [orderDiff , setOrderDiff] = useState(undefined);
  const orderIntervalRef = useRef(undefined)

  const brokers = useSelector((store) => store.clientinfo.value.brokers);

  const [strategy, setStrategy] = useState(undefined);
  const [show, setShow] = useState(false);

  useEffect(() => {
    loadStrategy();
    return ()=>{
      if(orderIntervalRef!=undefined)
        clearInterval(orderIntervalRef.current);
    }
  }, []);

  const handlePopClose = () => {
    setShow(false);
  };
  const loadStrategy = async () => 
  {
    const url = URLS.GET_ACTIVE_STRATEGY + obj.strategy;
    const res = await apiCall.getCallWithToken(url, token);
    console.log(res);
    if (res.status) 
    {
      if(obj.isrunning){
        const diff = getOrderDiff(res.data);
        setOrderDiff(diff);
        startOrderCounter();
      }
      setStrategy(res.data);
    }
  };

  const unDepoly = () => {
    //alert(obj._id)
    const url = URLS.UNDEPLOY_STRATEGY + obj._id;
    apiCall.patchCallWithToken(url, token).then((res) => {
      if (res.status) {
        removeFromList(obj._id);
      }
    });
  };

  const edit = () => {
    setShow(true);
  };

  const stopStrategy = async () => {
    const did = obj._id;
    const URL = URLS.STOP_STRATEGY + did;
    const result = await apiCall.postCallWithToken(URL, token);
    if (result.status) {
      const newItem = { ...obj, isrunning: false };
      updateDeployItem(did, newItem);
      toast.success(result.msg);
      clearInterval(orderIntervalRef.current);
      setOrderDiff(undefined);
    } else {
      toast.error(result.msg);
    }
  };

  const startOrderCounter = ()=>{
    orderIntervalRef.current = setInterval(()=>{
      setOrderDiff(pdiff=>pdiff-1000);
    },1000);
  }

  const startStrategy = async () => {
    //console.log(obj)
    if (obj.broker == null || obj.broker == undefined) {
      toast.error("Choose Broker to Execute !");
    } else {
      const brokerDeployStatus = brokers.some(
        (b) => b.name == obj.broker && b.isdeployon
      );
      if (brokerDeployStatus) {
        const did = obj._id;
        const URL = URLS.START_STRATEGY + did;
        const result = await apiCall.postCallWithToken(URL, token);
        if (result.status) {
          const newItem = { ...obj, isrunning: true };
          updateDeployItem(did, newItem);
          toast.success(result.msg);

          const diff = result.data;
          setOrderDiff(diff);
          startOrderCounter();
        } else {
          toast.error(result.msg);
        }
      } else toast.error(`${obj.broker} Broker Deploy Off !`);
    }
  };

  function convertMilliseconds(ms) 
  {
    // Calculate total seconds
    let totalSeconds = Math.floor(ms / 1000);    
    // Calculate hours
    let hours = Math.floor(totalSeconds / 3600);    
    // Calculate minutes
    let minutes = Math.floor((totalSeconds % 3600) / 60);    
    // Calculate seconds
    let seconds = totalSeconds % 60;

    hours = hours<10?"0"+hours:hours;
    minutes = minutes<10?"0"+minutes:minutes;
    seconds = seconds<10?"0"+seconds:seconds;
    
    return `${hours} : ${minutes} : ${seconds}`;
  }

  return (
    <>
      {show ? (
        <EditDeployStrategyPop
          name={strategy?.name}
          obj={obj}
          brokers={brokers}
          updateDeployItem={updateDeployItem}
          show={show}
          handlePopClose={handlePopClose}
        />
      ) : (
        <></>
      )}
      {strategy ? (
        <>
          <div className="col-xl-4 col-lg-5">
            <h4 style={{ fontSize: "1.2em" }}>
              {strategy?.name} ({JSON.parse(strategy.instrument).name})
            </h4>
            &nbsp;
            <b className="text-danger border shadow">{obj.broker}</b> <br />
            {strategy.legs.map((l) => (
              <>
                <b
                  style={{ fontSize: "0.8em" }}
                  className={l.type == "BUY" ? "text-success" : "text-danger"}
                >
                  {`${l.ttype}${l.qty * obj.qtyMultiply}${l.tm.toUpperCase()}`}
                </b>{" "}
                &nbsp;&nbsp;
              </>
            ))}
          </div>
          <div className="col-xl-2 col-lg-2 d-flex justify-content-center align-items-center">
            PNL :{" "}
            <CurrencyRupee style={{ fontSize: "1em" }} className="text-dark" />{" "}
            0.00
          </div>
          <div className="col-xl-3 col-lg-2 d-flex justify-content-center align-items-center">
            {obj.isrunning ? (
              <button className="btn-sm btn-danger" onClick={stopStrategy}>
                Stop
              </button>
            ) : (
              <button className="btn-sm btn-primary" onClick={startStrategy}>
                Start
              </button>
            )}
            &nbsp;
            <button className="btn-sm btn-primary">SquareOff</button>
          </div>

          <div className="col-xl-2 col-lg-1 d-flex justify-content-evenly align-items-center">
            {obj.isrunning ? <> 
              <b className={(orderDiff/1000)%2==0?'text-danger':'text-success'}>{convertMilliseconds(orderDiff)}</b>
            </>: (
              <>
                <button onClick={unDepoly} className="btn-sm btn-danger">
                  <FaTrash />
                </button>
                <EditSharp
                  onClick={edit}
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                  title="Edit"
                />
              </>
            )}
            <FaChevronDown />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );

  function getOrderDiff(strategy)
{
const td = getIndianDate();
const start_time = strategy.start_time;
let timeString = `${td.getFullYear()}-${td.getMonth()}-${td.getDate()} ${start_time}`;
let strategy_timestamp = Date.parse(timeString);

const cTimeStr = parseTimeDate();
let ctimeString = `${td.getFullYear()}-${td.getMonth()}-${td.getDate()} ${cTimeStr}`;
let current_timestamp = Date.parse(ctimeString);
const diff = strategy_timestamp - current_timestamp;
return diff;
}

function getIndianDate()
{
  const date = new Date();
  //console.log(date)
  
  const offsetInMinutes = date.getTimezoneOffset();
  
  let utcDate = undefined;
  
  if(offsetInMinutes<0)
    utcDate = new Date(date.getTime() + (Math.abs(offsetInMinutes)*60*1000));
  else if (offsetInMinutes>0)
    utcDate = new Date(date.getTime() - (Math.abs(offsetInMinutes)*60*1000));
  else
    utcDate = date;

  return utcDate;
}

function parseTimeDate() {
  const tm = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  });
  const isPM = tm.includes("PM");

  const i1 = tm.indexOf(":");
  const i2 = tm.indexOf(" ", i1);

  let hour = tm.substring(i1 - 2, i1);
  if (isPM && hour != 12) {
    hour = hour * 1 + 12;
  }

  let time = "" + hour + tm.substring(i1, i2);
  return time.trim();
}
};

function EditDeployStrategyPop({
  name,
  obj,
  brokers,
  show,
  handlePopClose,
  updateDeployItem,
}) {
  let popObj = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    height: "fit-content",
    width: "max-content",
    maxWidth: "70%",
    minWidth: "400px",
    backgroundColor: "white",
    borderRadius: "10px",
  };
  const token = useSelector((store) => store.user.value.token);
  const [isAccept, setIsAccept] = useState(false);

  const qtyMultiplyRef = useRef();
  const brokerRef = useRef();
  const squareOffRef = useRef();

  const updateItem = async () => {
    const qtyMultiply = qtyMultiplyRef.current.value * 1;
    const broker = brokerRef.current.value;
    const squareoff = squareOffRef.current.value;

    const newItem = {
      ...obj,
      qtyMultiply,
      broker: broker.length == 0 ? null : broker,
      squareoff,
    };
    console.log(newItem);
    const URL = URLS.EDITDEPLOY_STRATEGY + obj._id;
    const result = await apiCall.putCallWithToken(URL, token, newItem);
    if (result.status) {
      updateDeployItem(obj._id, newItem);
      handlePopClose();
      toast.success("Successfully Updated !");
    }
  };

  return (
    <div
      className={`modal fade ${show ? "show d-block" : ""}`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div style={popObj} className="p-4">
        <div className="modal-header">
          <h5 className="modal-title">{name}</h5>
          <button type="button" className="close" aria-label="Close">
            <span aria-hidden="true" onClick={handlePopClose}>
              &times;
            </span>
          </button>
        </div>
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="quantity">Quantity Multiplied</label>
              <input
                type="number"
                ref={qtyMultiplyRef}
                className="form-control"
                id="quantity"
                defaultValue={obj.qtyMultiply}
                min="1"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="brokerName">Broker Name</label>
              <select
                className="form-control"
                ref={brokerRef}
                id="brokerName"
                defaultValue={obj.broker}
              >
                <option value="">Select Broker</option>
                {brokers.map((b) => (
                  <option disabled={!b.isdeployon}>{b.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="maxProfit">Max Profit (Optional)</label>
              <input
                type="number"
                className="form-control"
                id="maxProfit"
                defaultValue="0"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="squareOffTime">Square Off Time</label>
              <input
                type="time"
                className="form-control"
                ref={squareOffRef}
                id="squareOffTime"
                defaultValue={obj.squareoff}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="maxLoss">Max Loss (Optional)</label>
              <input
                type="number"
                className="form-control"
                id="maxLoss"
                defaultValue="0"
              />
            </div>
            {/* <div className="form-group col-md-6">
              <label>Deployment Type</label>
              <div className="d-flex">
                <button type="button" className="btn btn-outline-primary mr-2">
                  Live
                </button>
                <button type="button" className="btn btn-primary">
                  Forward Test
                </button>
              </div>
            </div> */}
          </div>
          <div className="form-group">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="terms"
                checked={isAccept}
                onChange={(e) => setIsAccept(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="terms">
                I accept all the <a href="/"> &nbsp;terms & conditions</a>
              </label>
            </div>
          </div>
        </form>
        <hr />
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handlePopClose}
        >
          Close
        </button>
        &nbsp;&nbsp;
        {isAccept ? (
          <button
            type="button"
            className="btn btn-success"
            onClick={updateItem}
          >
            Update
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
