import { useEffect, useState } from "react";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Timer3Sharp, WatchLaterSharp, WatchOffSharp } from "@mui/icons-material";

export default function ClientStrategyList() 
{
  const navigate = useNavigate();
  const [strategyList, setStrategyList] = useState([]);
  const [deployList,setDeployList] = useState([]);
  const token = useSelector((store) => store.user.value.token);
  
  
  useEffect(() => {
    loadDepStrategy();
    loadStrategy();
  }, []);
  const loadDepStrategy = async () => 
  {    
    const depStrList = await apiCall.getCallWithToken(URLS.LIST_DEPLOY_STRATEGY,token);
    setDeployList(depStrList.data.map(ob=>ob.strategy*1));
  };
  const loadStrategy = async () => 
    {    
      const resp = await apiCall.getCallWithToken(URLS.LIST_ACTIVE_STRATEGY, token);
      //console.log(resp)
      setStrategyList(resp.data);
    };
  
  const deployStrategy = (id)=>
  {
    const url = URLS.DEPLOY_STRATEGY+id;
    apiCall.postCallWithToken(url,token).then(res=>
    {
      if(res.status)
      {
        setDeployList([...deployList,id])
      }
    })
  }
  return (
    <>
      <div className="container-fluid pt-3">
        <h3 id="HeadText">
          Master Strategies
        </h3>

        <div className="row mt-3">
          {strategyList.map((ob,index)=>{
            const wkDays = JSON.parse(ob.week_days);
            return <div key={`str${index}`} className="col-xl-5 col-lg-6 mt-3 card-body border border shadow ml-5 rounded-3 p-3">
            <div className="row">
              <div className="col-6">
                <h4>{ob.name}</h4>
                <b className="text-danger">ON : {JSON.parse(ob.instrument).name}</b> <br/><br/>
                {Object.keys(wkDays).map(wk=>
                <b style={{height:"10px",width:"10px"}} className={`border rounded p-1 m-1 shadow ${wkDays[wk]?'bg-success text-light':''}`}>{wk[0]}</b>)}
              </div>
              <div className="col-6">
                  <WatchLaterSharp/> Start : {ob.start_time} <br/>
                  <WatchLaterSharp/> Square : {ob.squareoff_time} <br/><br/>
                  Type : {ob.type} <br/>Segment : {JSON.parse(ob.instrument).optype}
              </div>
            </div>
            <hr/>
        
            {ob.legs.map(l=><>
              <b style={{fontSize:'15px'}} className={l.type == "BUY" ? "text-success border" : "text-danger border"}>
                  {`${l.ttype}${l.qty}${l.tm.toUpperCase()}`}
                </b> &nbsp;&nbsp;
            </>)}
            <br/><br/>

            {deployList.includes(ob.id)?<button className="btn btn-sm btn-outline-danger" onClick={()=>navigate("/client/mystrategy")}>
              Remove Strategy
            </button>:<button onClick={()=>deployStrategy(ob.id)} className="btn btn-sm btn-outline-primary">Add Strategy</button>}
            
          </div> } )}
        </div>
      </div>
    </>
  );
}
