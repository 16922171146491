import { configureStore } from "@reduxjs/toolkit";
import UserReducer from './UserSlice';
import ClientDataReducer from './ClientDataSlice';

const store = configureStore({
    reducer : {
        user : UserReducer,
        clientinfo : ClientDataReducer
    }
});

store.subscribe(()=>
{
    const allState = store.getState();
    localStorage.setItem('userinfo',JSON.stringify(allState.user.value));
    localStorage.setItem('clientinfo',JSON.stringify(allState.clientinfo.value));
})



export default store;