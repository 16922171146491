import { Outlet } from "react-router-dom";
// import Dashboard from "./ClientDashBoard";
import Nav from "./ClientNav";
import SideNav from "./ClientSideNav";
import '../sb-admin.css'
export default function ClientMain() 
{
  return <>
      <div id="wrapper">
        <SideNav/>
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
                <Nav/>
                <Outlet/>
          </div>
        </div>
      </div>
    </>
}
