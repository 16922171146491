import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import apiCall, { URLS } from "../../webservice/ApiCallService";
import { Link } from "react-router-dom";

import BrokerTerminal from "./BrokerTerminal";

export default function ClientDashboard() 
{
  const loginUser = useSelector((store) => store.user.value);
  const brokers = useSelector((store)=> store.clientinfo.value.brokers)
 
  console.log(">>> ",brokers)
  // useEffect(() => {
  //   loadBrokers();
  // }, []);

  // const loadBrokers = () => 
  // {
  //   apiCall
  //     .getCallWithToken(URLS.LIST_CLIENT_BROKERS, loginUser.token)
  //     .then((result) => {
  //       if (result.status) 
  //         setBrokers(result.data);
  //       else setBrokers([]);
  //     });
  // }; 

  return (
    <>
      <div className="container-fluid p-2">
        {/* Top Profile Box */}
        <h4 className="font-weight-bold text-info">
          Welcome {loginUser.name}
        </h4>
        {brokers.length==0?<div className="text-center">
          <h1 className="alert-danger text-center">No Broker Added Yet !</h1>
          <Link to="/client/brokers"><b className="btn btn-success">Add Broker</b></Link>
        </div>:<BrokerTerminal brokers={brokers}/>}
      </div>
    </>
  );
}


