import { useState } from "react";
import { FaCopy, FaPlus, FaTrash } from "react-icons/fa";
import "../custom-admin.css";
export default function TimeBased({ addleg, legs ,removeleg,copyleg,updateLeg,instrument}) 
{
  //console.log(instrument)
  const getItmOtm = ()=>{
     const arr = []
     for(let i=1;i<=25;i++)
        arr.push(i*50)
     return arr;
  }  
  const expiryList = {
    "NIFTY 50" : ["Weekly","Monthly"],
    "NIFTY Bank" : ["Monthly"],
    "NIFTY FIN" : ["Monthly"],
  }
  return (
    <>
      <h2 className="setting-ttl">Time Based Settings</h2>
      <button  type='button' onClick={addleg} className="btn btn-success">
        <FaPlus />
        &nbsp;&nbsp; Add Legs
      </button>

      {legs.map((ob,index) => (
       <div key={`legs${index}`} className="row justify-content-around align-items-center mt-3 p-3 bg-light shadow-sm rounded">

           <select className="form-select col-xl-1 col-lg-2 col-md-3 col-sm-6 mb-2" value={ob.type} onChange={(e)=>updateLeg(e.target,index)} data-name="type">
               <option>BUY</option>
               <option>SELL</option>
           </select>
           <input type="number" step={instrument?.lot} value={ob.qty} onChange={(e)=>updateLeg(e.target,index)} data-name="qty" className="form-control col-xl-1 col-lg-2 col-md-3 col-sm-6 mb-2" placeholder="Qty" style={{width: '75px'}}/>
           <select className="form-select col-xl-1 col-lg-2 col-md-3 col-sm-6 mb-2" value={ob.ttype} onChange={(e)=>updateLeg(e.target,index)} data-name="ttype">
               <option>CALL</option>
               <option>PUT</option>
           </select>
           <select className="form-select col-xl-1 col-lg-2 col-md-3 col-sm-6 mb-2" value={ob.expiry} onChange={(e)=>updateLeg(e.target,index)} data-name="expiry">
               <option value=''>Choose</option> 
               {expiryList[instrument?.name]?.map(e=><option value={e}>{e}</option>)}
           </select>
           <select className="form-select col-xl-1 col-lg-2 col-md-3 col-sm-6 mb-2 w-100" value={ob.tm} onChange={(e)=>updateLeg(e.target,index)} data-name="tm">
               {getItmOtm().reverse().map((val,index)=><option key={`itm${index}`} value={`itm${val}`}>ITM {val}</option>)}
               <option>ATM</option>
               {getItmOtm().map((val,index)=><option key={`otm${index}`} value={`otm${val}`}>OTM {val}</option>)}
           </select>
           <div className="d-flex align-items-center col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-2">
               <select className="form-select" onChange={(e)=>updateLeg(e.target,index)} data-name="sl-type"  value={ob.sl.type}>
                   <option value="Point">SL PL</option>
                   <option value="Percentage">SL %</option>
               </select>
               <input className="form-control ms-2" type="number" placeholder="SL" value={ob.sl.value} onChange={(e)=>updateLeg(e.target,index)} data-name="sl-value"/>
           </div>
           <div className="d-flex align-items-center col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-2">
               <select className="form-select" onChange={(e)=>updateLeg(e.target,index)} data-name="tp-type" value={ob.tp.type}>
                   <option value="Point">TP PL</option>
                   <option value="Percentage">TP %</option>
               </select>
               <input className="form-control ms-2" type="number" onChange={(e)=>updateLeg(e.target,index)} data-name="tp-value" placeholder="TP" value={ob.tp.value} />
           </div>
      
   
            <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6 mb-2 d-flex justify-content-center align-items-center">
                <FaTrash color="red" onClick={() => removeleg(ob)} className="action-icon mx-2"/>
                <FaCopy color="blue" onClick={() => copyleg(ob)} className="action-icon mx-2"/>
            </div>
   </div>
   
      ))}
    </>
  );
}
