import { createSlice } from "@reduxjs/toolkit";

function getLocalData()
{
    const uinfo = localStorage.getItem('userinfo');
    if(uinfo==undefined){
        return {
            islogin : false , 
            name : undefined,
            token : undefined,
            role : undefined,
            terminalStatus : false,
            deployStatus  : false
        }
    }else
        return JSON.parse(uinfo)
}

const slice = createSlice({
    name : 'userslice',
    initialState:{
        value : getLocalData()
    },
    reducers:
    {
        setUserData : (state,action)=>{
            state.value = {...action.payload,islogin:true}
        },
        delUserData : (state,action)=>{
            state.value = {
                islogin : false , 
                name : undefined,
                token : undefined,
                role : undefined,
                terminalStatus : false,
                deployStatus  : false
            }
        },
        refreshToken : (state,action)=>
        {
            if(state.value.islogin)
            {
             state.value = {...state.value,token:action.payload}
            }
        },
    }
})

export default slice.reducer;
export const {setUserData,delUserData,refreshToken} = slice.actions;