import { Link, useParams } from 'react-router-dom';
import ClientStrategyList from './ClientStrategyList';
import ClientDeployStrategyList from './ClientDeployStrategyList';

export default function ClientStrategies()
{
    const { type } = useParams();

    return <>
        &nbsp;&nbsp;
        <Link to="/client/strategy/master"><b className='btn btn-outline-primary'>Master</b></Link>
        &nbsp;&nbsp;
        <Link to="/client/strategy/deploy"><b className='btn btn-outline-primary'>Deployed</b></Link>
        <hr/>
        {type=="master"?<ClientStrategyList/>:<ClientDeployStrategyList/>}
    </>
}