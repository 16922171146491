import { useEffect, useState } from 'react';
import webService,{URLS} from '../../webservice/ApiCallService';
import { useSelector } from 'react-redux';
import "./custom-admin.css";
import { DataGrid } from '@mui/x-data-grid';
export default function Messages()
{
    const loginUserToken = useSelector((store) => store.user.value.token);
    const [msgs,setMsgs] = useState([])
    useEffect(()=>{
        loadMsgs()
    },[])

    const loadMsgs = async ()=>
    {
        const resp = await webService.getCallWithToken(URLS.LOAD_MSGS,loginUserToken);
        // console.log(resp.data);
        setMsgs(resp.data.map((ob,ind)=>{return {...ob,index:ind+1}}));
    }
    const columns = [
        { field: "index", headerName: "S. No.", width: 70 },
        { field: "name", headerName: "Name", width: 200 },
        { field: "mobile", headerName: "Mobile", width: 200 },
        { field: "email", headerName: "Email", width: 200 },
        {
          field: "message",
          headerName: "Message",
          width: 300,
        },
      ];
    
    return <div className="container-fluid pt-3">
            <h3 id="HeadText">Client Messages</h3>
            <div style={{ height: 400, width: "100%" }}>
          <DataGrid rows={msgs} columns={columns} pageSize={5} />
        </div>
    </div>
}