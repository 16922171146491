import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import apiCall, { URLS } from "../../webservice/ApiCallService";

export default function ClientTracker() {
  const location = useLocation();
  const { name, id } = location.state;
  const loginUserToken = useSelector((store) => store.user.value.token);
  const [showDets,setShowDets]=useState(false);
  const [trackBroker, setTrackBroker] = useState([]);
  const [trackRunnStrategy, setTrackRunnStrategy] = useState([]);
  const [terminalTrack, setTerminalTrack] = useState([]);

  useEffect(() => {
    loadDetails();
  }, [showDets]);

  const loadDetails = async () => {
    const detsUrl = URLS.GET_CLIENT_TRACK + `/${id}`;
    const resp = await apiCall.getCallWithToken(detsUrl, loginUserToken);
    setTrackBroker(resp.data.brokers);
    setTerminalTrack(resp.data.terminalTrackers);
    setTrackRunnStrategy(resp.data.runningStrategies);

  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
            <div className="col-xl-4"> <h3 id="HeadText">CLIENT TRACKER</h3></div>
            <div className="col-xl-6 text-center text-success"><h4><b>{name}</b></h4></div>
        </div>
       
        <hr />
        <div className="row g-1">
            {trackBroker.map((bro,i)=><div key={" "+bro.id+i} style={{fontSize:"12px"}} className={`col-xl-3 col-lg-2 col-md-4 col-sm-6 col-xs-12 border rounded ${showDets===bro.id?"border-success":"border-light"} p-1`}  onClick={(e)=>setShowDets(bro.id)}>
            <div className="card-body" role="button">
              <h6 className="text-decoration-underline">{bro.name}</h6>
              <div className="d-flex flex-wrap  justify-content-evenly">
              <div className="flex-item"><span style={{background:bro.isterminalon?"green":"red",borderRadius:"50%",height:"10px",width:"10px",display:"flex"}}></span><b>Terminal</b></div>
              <div className="flex-item"><span style={{background:bro.isdeployon?"green":"red",borderRadius:"50%",height:"10px",width:"10px",display:"flex"}}></span><b>Deploy</b></div>
              </div>
            </div>
          </div>
           )}
        </div>
        {(showDets)?
        <div className="container">
            <table className="table">
                <thead>
                  <tr>
                  <th className="text-dark bg-light text-center">Terminal ON/OFF</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {terminalTrack.map((ob,i)=>{if(parseInt(ob.broker)===showDets){return<tr key={" "+ob.id+i}><td>{ob.action} : {ob.datetime.substring(ob.datetime.indexOf("T")+1,ob.datetime.indexOf("."))}</td></tr>}})}
                </tbody>
            </table>
        </div>
        :""}

      </div>
    </>
  );
}
