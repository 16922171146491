import React, { useState } from 'react';
import './custom-admin.css';
import {  FaBars, FaBell, FaCog, FaEnvelope} from 'react-icons/fa';
import Profile from './AdminProfile';
import {  useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {delUserData} from '../../reduxconfig/UserSlice';

export default function AdminNav() 
{
  const loginUserRole = useSelector((store) => store.user.value.role);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPop, setShowPop] = useState(false);
  const handleOpenPop = () => setShowPop(true);
  const handleClosePop = () => setShowPop(false);
  const logout = ()=>
    {    
        toast.success('Thanks , See You Soon !')
        dispatch(delUserData())
        navigate("/user/login")
    }
  return <>
   <Profile show={showPop} handleClose={handleClosePop} />
     <nav className="navbar">
      <div className="navbar-brand">
      <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
          <FaBars/>
      </button>
      </div>

      <div className="navbar-icons">
        <div className="icon-container profile">
          <FaBell/>
          <span className="badge">5</span>
          {/* <div className="dropdown-menu">
            <ul>
              <li>My Profile</li>
              <li>Settings</li>
              <li>Logout</li>
            </ul>
          </div> */}
        </div>

        <div className="icon-container profile">
          <FaEnvelope />
          <span className="badge">3</span>
          {/* <div className="dropdown-menu">
            <ul>
              <li>My Profile</li>
              <li>Settings</li>
              <li>Logout</li>
            </ul>
          </div> */}
        </div>
        <div className="icon-container profile">
          <FaCog />
          <span className="badge">3</span>
          <div className="dropdown-menu">
            <ul>
              <li onClick={handleOpenPop}>My Profile</li>
              <li onClick={logout}>Logout</li>
            </ul>
          </div>
        </div>

        {/* Profile with hover dropdown */}
        
      </div>
    </nav>
 
  </>
}
