import { useEffect, useState } from "react";
import apiCall, { URLS } from "../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
// import CheckIcon from "@mui/icons-material/Check";
// import CloseIcon from "@mui/icons-material/Close";

export default function BlockUser() {
  const loginUserToken = useSelector((store) => store.user.value.token);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    loadBlockedUsers();
  }, []);

  const loadBlockedUsers = async () => {
    const resp = await apiCall.getCallWithToken(
      URLS.LIST_BLOCK_USERS,
      loginUserToken
    );
    setUsers(resp.data.map((ob,index)=>{return {...ob,index:index+1}}));
  };

  const unBlockUser = (userId) => {
    apiCall.patchCallWithToken(
      URLS.CHANGE_BLOCK_STATUS + `/${userId}`,
      loginUserToken
    );
    setUsers(users.filter((ob) => ob.id !== userId));
  };

  const askPop = (event, ob) => {

    toast.custom((t) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "300px",
          margin: "0 auto",
        }}
      >
        <p>Do you want to unblock {ob.name} </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <button
            onClick={() => {
              // Perform activation logic here
              unBlockUser(ob.id);
              toast.dismiss(t.id);
              toast.success(`${ob.name} is unusers`);
            }}
            style={{
              backgroundColor: "#4caf50",
              color: "#fff",
              border: "none",
              padding: "8px 16px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Yes
          </button>
          <button
            onClick={() => {
              toast.dismiss(t.id);
              toast.error("Cancelled");
            }}
            style={{
              backgroundColor: "#f44336",
              color: "#fff",
              border: "none",
              padding: "8px 16px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            No
          </button>
        </div>
      </div>
    ));
  };

  const columns = [
    { field: "index", headerName: "S. No.", width: 70 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "role", headerName: "Role", width: 200 },
    { field: "mobile", headerName: "Mobile", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "active_status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.value === true
                ? "green"
                : params.value === false
                ? "gray"
                : "red",
          }}
        >
          {params.value === true ? "active" : "inactive"}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => {
        const { id, status } = params.row;
        return (
          <>
            {/* {status !== 'users' && (
              <Button
                variant="contained"
                color={status === 'active' ? 'secondary' : 'primary'}
                startIcon={status === 'active' ? <CloseIcon /> : <CheckIcon />}
                onClick={() =>askPop(id, status === 'active' ? 'inactive' : 'active')}
                style={{ marginRight: '10px' }}
              >
                {status === 'active' ? 'Deactivate' : 'Activate'}
              </Button>
            )} */}
            <Button
              variant="contained"
              color="error"
              startIcon={<BlockIcon />}
              onClick={(e) => askPop(e, params.row)}
            >
              UnBlock
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="container-fluid pt-3">
        <h3 id="HeadText">Blocked Users</h3>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid rows={users} columns={columns} pageSize={5} />
        </div>
      </div>
    </>
  );
}
