import React from 'react';
import './custom-client.css';
import {  FaBell, FaEnvelope} from 'react-icons/fa';

export default function ClientNav() 
{
  return <>
     <nav className="navbar">
      <div className="navbar-brand"></div>

      <div className="navbar-icons">
        <div className="icon-container profile">
          <FaBell/>
          <span className="badge">5</span>
          <div className="dropdown-menu">
            <ul>
              <li>My Profile</li>
              <li>Settings</li>
              <li>Logout</li>
            </ul>
          </div>
        </div>

        <div className="icon-container profile">
          <FaEnvelope />
          <span className="badge">3</span>
          <div className="dropdown-menu">
            <ul>
              <li>My Profile</li>
              <li>Settings</li>
              <li>Logout</li>
            </ul>
          </div>
        </div>

        {/* Profile with hover dropdown */}
        
      </div>
    </nav>
 
  </>
}
