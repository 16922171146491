
import toast from 'react-hot-toast';
import apiCall , {URLS} from '../webservice/ApiCallService';
import { useRef, useState } from 'react';


export default function ContactForm()
{
  const nameBox = useRef()
  const mobBox = useRef()
  const emailBox = useRef()
  const msgBox = useRef()

  const save = async (event)=>
  {
    event.preventDefault()
    const data = {
      name : nameBox.current.value,
      mobile : mobBox.current.value,
      email : emailBox.current.value,
      message : msgBox.current.value
    }
    
    toast.promise(apiCall.postCall(URLS.SAVE_MSGS,data),{
      loading : 'Sending ..',
      success : "Message Sended !",
      error : "Message Failed !"
    })
    
   
    event.target.reset()
    
  }
  
  return <>
    <form className="text-center" onSubmit={save}>
                    <div className="col-xl-12 col-lg-6 col-md-3 text-center">
                    <div className="row p-2 justify-content-center">
                      <div className="col-xl-10 col-lg-12">
                        <input
                          ref={nameBox}
                          type="text"
                          required
                          placeholder="Full Name"
                          className="form-control"
                        />
                      </div>
                      </div>
                      <div className="row p-2 justify-content-center">
                      <div className="col-xl-10 col-lg-12">
                        <input
                          ref={emailBox}
                          type="email"
                          required
                          placeholder="Email Address"
                          className="form-control"
                        />
                      </div>
                      </div>
                      <div className="row p-2 justify-content-center">
                      <div className="col-xl-10 col-lg-12">
                        <input
                          ref={mobBox}
                          type="tel"
                          required
                          placeholder="Mobile No."
                          className="form-control"
                        />
                      </div>
                      </div>
                      <div className="row p-2 justify-content-center">
                      <div className="col-xl-10 col-lg-12">
                        <input
                          ref={msgBox}
                          type="text"
                          required
                          placeholder="Message"
                          className="form-control"
                        />
                      </div>
                      </div>
                      </div>
                    <div className="text-center mt-2 ">
                      <button
                        type="submit"
                        name="Submit"
                        className="btn btn-primary btn-md"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
    </>
}