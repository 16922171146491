import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function StrategyList() {
  const navigate = useNavigate();
  const [editID, setEditID] = useState(undefined);
  const [showPop, setShowPop] = useState(false);
  const handleOpenPop = (id) => {
    setShowPop(true);
    setEditID(id);
  };
  const handleClosePop = () => setShowPop(false);
  const [strategyList, setStrategyList] = useState([]);
  const token = useSelector((store) => store.user.value.token);
  useEffect(() => {
    loadStrategy();
  }, [strategyList]);
  const loadStrategy = async () => {
    const resp = await apiCall.getCallWithToken(URLS.LIST_STRATEGY, token);
    setStrategyList(
      resp.data.map((ob, ind) => {
        return { ...ob, index: ind + 1 };
      })
    );
  };
  const changeStatus = (userId) => {
    apiCall.patchCallWithToken(
      URLS.CHANGE_STATUS_STRATEGY + `${userId}`,
      token
    );
    loadStrategy();
  };
  
  return (
    <>
      <div className="container-fluid pt-3">
        <h3 id="HeadText">
          List Strategies
        </h3>

        <table className="table">
          <thead className="text-danger">
            <tr className="text-center">
              <th>S. No. </th>
              <th>Strategy Name </th>
              <th>Strategy Type </th>
              <th>Strategy Legs </th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            {strategyList.map((ob,i) => {
              let instrument = JSON.parse(ob.instrument);
              let legs = ob.legs
              return (
                <tr key={`strategyList${i}`} className="text-center">
                  <td className="text-dark">{ob.index}</td>
                  <td className="text-dark">
                    <div>
                      <span><b>{ob.name}</b></span>
                      <br />
                      <span>{instrument.name}</span>
                    </div>
                  </td>
                  <td>
                    <b>{ob.type}</b>
                  </td>
                  <td>
                    <b>{legs.length}</b>
                  </td>
                  <td>
                  <div className="d-flex justify-content-evenly">
                    <button className="btn btn-sm btn-outline-primary" onClick={()=>navigate(`/admin/strategy/${ob.id}`)}>Edit</button>
                    {ob.status?<button className="btn btn-sm btn-outline-danger" onClick={()=>changeStatus(ob.id)}>Deactive</button>:<button className="btn btn-sm btn-outline-success" onClick={()=>changeStatus(ob.id)}>Active</button>}
                  </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
