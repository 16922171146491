const SERVER = process.env.REACT_APP_ENV=='dev'?process.env.REACT_APP_SERVER_DEV:process.env.REACT_APP_SERVER_PROD;

export const URLS = {
    REGISTER : `${SERVER}/profitology/register`,
    LOGIN : `${SERVER}/profitology/login`,
    LIST_MASTERBROKER : `${SERVER}/profitology/listmasterbrokers`,
    RESET_TOKEN : `${SERVER}/auth/resettoken`,
    LOAD_MSGS : `${SERVER}/auth/subadmin/listcontact`,
    SAVE_MSGS : `${SERVER}/profitology/savecontact`,

    SAVE_HOLIDAYS : `${SERVER}/auth/admin/save-holidays`,
    LIST_HOLIDAYS : `${SERVER}/auth/admin/list-holidays`,


    SAVE_MANAGER : `${SERVER}/auth/admin/savemanager`,
    LIST_USERS : `${SERVER}/auth/subadmin/listusers`,
    STATUS_CHANGE:`${SERVER}/auth/subadmin/changeuserstatus`,
    CHANGE_BLOCK_STATUS:`${SERVER}/auth/admin/changeblock`,
    LIST_BLOCK_USERS:`${SERVER}/auth/admin/listblockusers`,
    GET_CLIENT_TRACK:`${SERVER}/auth/subadmin/running-tracker`,

    SAVE_BROKER : `${SERVER}/auth/client/savebroker`,
    LIST_CLIENT_BROKERS : `${SERVER}/auth/client/mybrokers`,
    SET_DEFAULT_BROKER : `${SERVER}/auth/client/setdefaultbroker`,
    GET_DEFAULT_BROKER : `${SERVER}/auth/client/defaultbroker`,
    SAVE_STRATEGY : `${SERVER}/auth/admin/save-strategy`,
    LIST_STRATEGY:`${SERVER}/auth/subadmin/list-strategy`,
    LIST_ACTIVE_STRATEGY : `${SERVER}/auth/client/strategies`,
    GET_ACTIVE_STRATEGY : `${SERVER}/auth/client/strategy/`,
    DEPLOY_STRATEGY : `${SERVER}/auth/client/deploy-strategy/`,
    UNDEPLOY_STRATEGY : `${SERVER}/auth/client/undeploy-strategy/`,
    EDITDEPLOY_STRATEGY : `${SERVER}/auth/client/editdeploy-strategy/`,
    LIST_DEPLOY_STRATEGY : `${SERVER}/auth/client/deploy-strategy-list`,
    LIST_RUNNING_STRATEGY : `${SERVER}/auth/client/running-strategy-list/`,
    CHANGE_STATUS_STRATEGY:`${SERVER}/auth/admin/change-status-strategy/`,
    GET_ADMIN_STRATEGY : `${SERVER}/auth/admin/get-strategy/`,
    UPDATE_STRATEGY : `${SERVER}/auth/admin/update-strategy/`,

    START_STRATEGY : `${SERVER}/auth/client/start-strategy/`,
    STOP_STRATEGY : `${SERVER}/auth/client/stop-strategy/`,

    LIST_ORDERS : `${SERVER}/auth/client/list-orders`,
    BROKER_LOGIN : `${SERVER}/auth/client/broker/login`,
    CHANGE_TERMINAL_STATUS : `${SERVER}/auth/client/broker/change-terminal-status/`,
    CHANGE_DEPLOY_STATUS : `${SERVER}/auth/client/broker/change-deploy-status/`,

    TEST : `${SERVER}/auth/client/broker/test/`,
}
class ApiCallService
{
    getCall(url)
    {
        return new Promise((resolve,reject)=>{
            fetch(url)
                .then(response=>response.json())
                .then(res=>resolve(res))
                .catch(err=>reject(err))
        })
    }
    postCall(url,data)
    {
        console.log(process.env.NODE_ENV , url)
        return new Promise((resolve,reject)=>{
            fetch(url,{
                method : "POST",
                headers:{
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(response=>response.json())
                .then(res=>resolve(res))
                .catch(err=>reject(err))
        })
    }

    getCallWithToken(url,token)
    {
        return new Promise((resolve,reject)=>{
            fetch(url,{
                headers:{
                    'Authorization' : 'Bearer '+token
                }
            })
                .then(response=>response.json())
                .then(res=>resolve(res))
                .catch(err=>reject(err))
        })
    }
    postCallWithToken(url,token,data)
    {
        return new Promise((resolve,reject)=>{
            fetch(url,{
                method : "POST",
                headers:{
                    'content-type': 'application/json',
                    'Authorization' : 'Bearer '+token
                },
                body: JSON.stringify(data)
            })
                .then(response=>response.json())
                .then(res=>resolve(res))
                .catch(err=>reject(err))
        })
    }
    patchCallWithToken(url,token)
    {
        return new Promise((resolve,reject)=>{
            fetch(url,{
                method : "PATCH",
                headers:{
                    'content-type': 'application/json',
                    'Authorization' : 'Bearer '+token
                }
            })
                .then(response=>response.json())
                .then(res=>resolve(res))
                .catch(err=>reject(err))
        })
    }
    putCallWithToken(url,token,data)
    {
        return new Promise((resolve,reject)=>{
            fetch(url,{
                method : "PUT",
                headers:{
                    'content-type': 'application/json',
                    'Authorization' : 'Bearer '+token
                },
                body: JSON.stringify(data)
            })
                .then(response=>response.json())
                .then(res=>resolve(res))
                .catch(err=>reject(err))
        })
    }
}

export default new ApiCallService();
