import { FaCircle, FaGreaterThan, FaLessThan } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import apiCall, { URLS } from "../../webservice/ApiCallService";
import toast from "react-hot-toast";
import OrderDetails from "./OrdersDetails";
import {addBrokers} from '../../reduxconfig/ClientDataSlice';
export default function BrokerTerminal({brokers})
{
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const next = () => {
      if (index == brokers.length - 1) setIndex(0);
      else setIndex(index + 1);
  };
  const previous = () => {
      if (index == 0) setIndex(brokers.length - 1);
      else setIndex(index - 1);
  };

  const changeBrokerTerminal = (bid)=> 
  {
    dispatch(addBrokers(brokers.map((b) => b.id == bid ? { ...b, isterminalon: !b.isterminalon } : b)));
  }

  const changeBrokerDeploy = (bid)=> 
  {
    dispatch(addBrokers(brokers.map((b) =>b.id == bid ? { ...b, isdeployon: !b.isdeployon } : b)));
  }
  return <>
    <div className="row mb-3">
      <div className="col-xl-1 col-md-1">
        <FaLessThan onClick={previous} className="text-danger" />
      </div>
      <div className="col-xl-2 col-md-2">
        <b className="text-succes">Switch Broker</b>
      </div>
      <div className="col-xl-1 col-md-1">
        <FaGreaterThan onClick={next} className="text-danger" />
      </div>
    </div>

     <BrokerInfo broker={brokers[index]} 
          changeBrokerTerminal={changeBrokerTerminal}
          changeBrokerDeploy={changeBrokerDeploy}/> 
  </>
}

function BrokerInfo({broker,changeBrokerTerminal,
  changeBrokerDeploy})
{
  // const [showPop, setShowPop] = useState(false);
  // const handleOpenPop = () => setShowPop(true);
  // const handleClosePop = () => setShowPop(false);
  //const [runningStrategies,setRunningStrategies] = useState([])

  const loginUser = useSelector((store) => store.user.value);
  const onCSS = {
    backgroundColor: "green",
    color: "green",
  };
  const offCSS = {
    backgroundColor: "#a32626",
    color: "#a32626",
  };

  // useEffect(()=>
  // {
  //     loadRunningStrategies();
  // },[broker]);
  // const loadRunningStrategies = async ()=>
  // {
  //     const URL = URLS.LIST_RUNNING_STRATEGY+broker.id;
  //     const result = await apiCall.getCallWithToken(URL,loginUser.token);
  //     if(result.status)
  //     {
  //       setRunningStrategies(result.data);
  //     }
  // }


  const handleTerminal = (event) => 
  {
    //const status = event.target.checked;

    if (broker.isdeployon) {
      toast.error("Deployment is ON !");
    } else {
      const bid = broker.id;
      const url = URLS.CHANGE_TERMINAL_STATUS + bid;

      toast.promise(apiCall.getCallWithToken(url, loginUser.token), {
        loading: "Terminal Process Running ...",
        success: (result) => {
          if (result.status) {
            changeBrokerTerminal(bid);
            toast.success(`${broker.name} Terminal Status Changed.`);
          } else toast.error(result.msg);
        },
      });
    }
  };

  const handleDeploy = (event) => 
  {
    const status = event.target.checked;

    if(broker.isterminalon)
    {
      const bid = broker.id;
      const url = URLS.CHANGE_DEPLOY_STATUS + bid;
      apiCall.getCallWithToken(url, loginUser.token).then((result) => 
      {
        if (result.status) {
          changeBrokerDeploy(bid);
          toast.success(`${broker.name} Deploy Status Changed.`);
        } else toast.error(result.msg);
      });
    }else{
      toast.error("Turn On Terminal First !");
    }
  };

  // const deployChange = (selectItem) => 
  //   {
  //     const bid = broker.id;
  //     const url = URLS.CHANGE_DEPLOY_STATUS + bid;
  
  //     toast.promise(
  //       apiCall.postCallWithToken(url, loginUser.token, {
  //         strategy: selectItem,
  //       }),
  //       {
  //         loading: "Deploy Process Running ...",
  //         success: (result) => {
  //           //console.log(result);
  //           if (result.status) {
  //             changeBrokerDeploy(bid);
  //             toast.success(`${broker.name} Deploy Status Changed.`);
  //             setTimeout(loadRunningStrategies,2000);
  //           } else toast.error(result.msg);
  //         },
  //       }
  //     );
  //   };

  return <>
    {/* <AskStrategyPopUp
        show={showPop}
        handleClose={handleClosePop}
        deployChange={deployChange}
      /> */}
    <div className="row">
        <div className="col-xl-12 col-md-12 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <h4 className="text-center text-danger">{broker.name}</h4>
              <div className="row mt-3">                
                <div className="col-xl-3 col-lg-3">
                  Strategy Output <br />
                  <i className="fa-solid fa-indian-rupee-sign"></i> 0.00
                </div>
                <div className="col-xl-4 col-lg-4">
                  <b className="text-danger">Terminal</b>
                  <div className="toggleswitchUser">
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="terminalStatusCheck"
                      onChange={handleTerminal}
                      checked={broker.isterminalon}
                    />
                    <label
                      htmlFor="terminalStatusCheck"
                      className="checkbox-label"
                      style={broker.isterminalon ? onCSS : offCSS}
                    >
                      <span className="ball"></span>
                    </label>
                    <b className="txt-ttl">
                      {broker.isterminalon ? "ON" : "OFF"}
                    </b>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3">
                  <b className="text-danger">Deploy Strategy</b>
                  <div className="toggleswitchUser">
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="deployStatusCheck"
                      onChange={handleDeploy}
                      checked={broker.isdeployon}
                    />
                    <label
                      htmlFor="deployStatusCheck"
                      className="checkbox-label"
                      style={broker.isdeployon ? onCSS : offCSS}
                    >
                      <span className="ball"></span>
                    </label>
                    <b className="txt-ttl">
                      {broker.isdeployon ? "ON" : "OFF"}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr/>
        {/* <!-- Content Row --> */}
        {/* <div className="row">
          <h5 className="text-danger text-center">Running Strategies</h5>
          {broker.isdeployon?runningStrategies.map(ob=><RunningStrategy strategy={ob} 
                  loadRunningStrategies={loadRunningStrategies}
                  />):""}          
        </div> */}
  </>
}

function RunningStrategy({strategy,loadRunningStrategies})
{
  const [showPop,setShowPop]=useState(false)
  return <><div className="col-xl-3 col-md-6 mb-4">
    {showPop && strategy.isorder?<OrderDetails strategy={strategy} setShowPop={setShowPop}/>:""}
  <div className="card border-left-primary shadow h-100 py-2 cursor" 
  style={{cursor:strategy.isrunning?"pointer":"default"}} 
  onClick={()=>{setShowPop(true)}}>
    <div className="card-body">
      <div className="row no-gutters align-items-center">
        <div className="col mr-2">
          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
            {strategy.instrument.name}
          </div>
          <div className="h5 mb-0 font-weight-bold text-gray-800">
          LOT : {strategy.instrument.lot}          
          </div>
          <b className={strategy.isrunning?"text-success":"text-danger"}>{strategy.message}</b>
        </div>
        <div className="col-auto">
          <FaCircle className={strategy.isrunning?"text-success":"text-danger"}/>
          {strategy.isrunning?<>
            <br/><br/>
            <RemainingClock strategy_start_time={strategy.start_time} loadRunningStrategies={loadRunningStrategies}/>
          </>:""}
        </div>
      </div>
    </div>
  </div>
</div>

</>
}

function RemainingClock({strategy_start_time,loadRunningStrategies})
{
  const bgArr = ['text-danger','text-success'];
  const [remtime,setRemTime] = useState({hour:0,minute:0,second:0})
  const tickintervalRef = useRef(undefined);
  const timeoutRef = useRef(undefined)

  useEffect(()=>
  {
    if(tickintervalRef.current!=undefined)
    {
      clearInterval(tickintervalRef.current);
      clearTimeout(timeoutRef.current);
    }
    remainingTimeToStart();
    return ()=>{
      console.log("CleanUp ...")
      clearInterval(tickintervalRef.current);
      clearTimeout(timeoutRef.current);
    }
  },[strategy_start_time]);

  const startTick = (seconds)=>{
      tickintervalRef.current = setInterval(()=>
      {       
        setRemTime((pv)=>
        {
          var {hour,minute,second} = calculateDiff();
          return {hour,minute,second};
        });
      },1000)

      //console.log("SS >> ", seconds)
      timeoutRef.current = setTimeout(()=>{
        clearInterval(tickintervalRef.current);
        setRemTime({hour:0,minute:0,second:0})
        loadRunningStrategies();
      },seconds)
  }

  const calculateDiff = ()=>{
       const start_time = strategy_start_time;
        let t = new Date();
        let timeString = `${t.getFullYear()}-${t.getMonth()}-${t.getDate()} ${start_time}`;
        let strategy_timestamp = Date.parse(timeString);

        const cTimeStr = t.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit',hour12: false });        
        let ctimeString = `${t.getFullYear()}-${t.getMonth()}-${t.getDate()} ${cTimeStr}`;        
        let current_timestamp = Date.parse(ctimeString);
        
        //console.log(strategy_timestamp , current_timestamp)
        let diff = strategy_timestamp - current_timestamp;
        
        const totalSeconds = Math.floor(diff / 1000); // Convert milliseconds to seconds
        const hour = Math.floor(totalSeconds / 3600); // 1 hour = 3600 seconds
        const minute = Math.floor((totalSeconds % 3600) / 60); // Remaining minutes
        const second = totalSeconds % 60; // Remaining seconds
        return {hour,minute,second,diff}
  }

  const remainingTimeToStart = ()=>
  {        
        var {hour,minute,second,diff} = calculateDiff();
        setRemTime({hour,minute,second});
        startTick(diff);    
  }

  return <>
      {remtime.hour+remtime.minute+remtime.second==0?"":<b className={bgArr[remtime.second%2]}>{remtime.hour<10?"0"+remtime.hour:remtime.hour} : {remtime.minute<10?"0"+remtime.minute:remtime.minute} : {remtime.second<10?"0"+remtime.second:remtime.second} </b>}
  </>
}
